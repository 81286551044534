import styled, { keyframes } from 'styled-components';

const aniTop = keyframes`
  0%{opacity: 0;  transform: translateY(100px);}
  100%{
    opacity: 1;  transform: translateY(0px);}
`;
export const Main01 = styled.article`
  margin-bottom: 80px;
  padding-top: 100px;
  @media screen and (max-width: 880px) {
    padding-top: 60px;
    margin-bottom: 60px;
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  .tit {
    text-align: left;
    margin-bottom: 100px;
    h2 {
      letter-spacing: -1.5px;
    }
    p {
      @media screen and (max-width: 1480px) {
        margin-left: 10px;
      }
      span {
        @media screen and (max-width: 950px) {
          display: inline;
        }
      }
    }
  }
`;
export const Main02 = styled.article`
  padding: 80px 0 100px;
  background-color: #f5f8fb;
  @media screen and (max-width: 900px) {
    padding: 70px 0 80px;
  }
  @media screen and (max-width: 580px) {
    padding: 60px 0;
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  h2 {
    color: #26334b;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 40px;
    letter-spacing: -1.5px;
    @media screen and (max-width: 900px) {
      font-size: 26px;
    }
    @media screen and (max-width: 580px) {
      font-size: 21px;
    }
  }
  .main02Img {
    max-height: 715px;
    height: 100%;
    text-align: center;
    min-height: 450px;
    @media screen and (max-width: 900px) {
      max-height: 1005px;
      min-height: 700px;
    }
    @media screen and (max-width: 580px) {
      min-height: 500px;
    }
    img {
      max-width: 100%;
      &:nth-of-type(1) {
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
      &:nth-of-type(2) {
        display: none;
        @media screen and (max-width: 900px) {
          display: inline;
        }
        @media screen and (max-width: 580px) {
          display: none;
        }
      }
      &:nth-of-type(3) {
        display: none;
        @media screen and (max-width: 580px) {
          display: inline;
        }
      }
    }
  }
`;
export const Main03 = styled.article`
  padding: 145px 0 70px;
  @media screen and (max-width: 900px) {
    padding: 100px 0 60px;
  }
  @media screen and (max-width: 580px) {
    padding: 70px 0;
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  .tit h2 {
    margin-bottom: 30px;
    letter-spacing: -1.5px;
    @media screen and (max-width: 900px) {
      margin-bottom: 20px;
    }
  }
  p {
    @media screen and (max-width: 1480px) {
      margin-left: 20px;
    }
    @media screen and (max-width: 580px) {
      margin-left: 16px;
    }
  }
`;
export const Main04 = styled.article`
  padding: 100px 0;
  background-color: #f5f8fb;
  text-align: center;
  @media screen and (max-width: 900px) {
    padding: 40px 0;
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  img {
    max-width: 100%;
    &:nth-of-type(1) {
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    &:nth-of-type(2) {
      display: none;
      @media screen and (max-width: 900px) {
        display: inline;
      }
      @media screen and (max-width: 580px) {
        display: none;
      }
    }
    &:nth-of-type(3) {
      display: none;
      @media screen and (max-width: 580px) {
        display: inline;
      }
    }
  }
  .inner {
    background-color: #fff;
    padding: 100px 45px 50px;
    box-shadow: 0px 10px 10px rgba(131, 134, 193, 0.1);
    @media screen and (max-width: 900px) {
      padding: 80px 30px 35px;
    }
    @media screen and (max-width: 580px) {
      padding: 50px 20px 25px;
    }
    .main04Img {
      /*max-height: 355px;
      height: 100%;*/
      height: 355px;
      margin-bottom: 100px;
      @media screen and (max-width: 1000px) {
        margin-bottom: 80px;
        max-height: 355px;
        height: 100%;
      }
      @media screen and (max-width: 580px) {
        margin-bottom: 50px;
      }
    }
    h3 {
      color: #3c86b5;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 10px;
      @media screen and (max-width: 900px) {
        font-size: 26px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 580px) {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .txtBox {
      padding: 60px;
      background-color: #fafbfd;
      text-align: left;
      @media screen and (max-width: 1300px) {
        padding: 60px 40px;
      }
      @media screen and (max-width: 900px) {
        padding: 50px 40px;
      }
      @media screen and (max-width: 580px) {
        padding: 40px 20px;
      }
      li {
        margin-bottom: 60px;
        @media screen and (max-width: 580px) {
          margin-bottom: 35px;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      h4 {
        color: #3c86b5;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 20px;
        line-height: 1;
        @media screen and (max-width: 900px) {
          font-size: 20px;
          margin-bottom: 15px;
        }
        @media screen and (max-width: 580px) {
          font-size: 18px;
        }
      }
      p {
        margin-left: 25px;
        span {
          color: #747474;
          font-size: 18px;
          font-weight: 400;
          display: block;
          @media screen and (max-width: 900px) {
            font-size: 16px;
          }
        }
        @media screen and (max-width: 580px) {
          margin-left: 0;
        }
      }
    }
  }
`;
export const Main05 = styled.article`
  padding: 140px 0;
  margin-bottom: 80px;
  background-color: #26334b;
  @media screen and (max-width: 990px) {
    padding: 110px 0 120px;
  }
  @media screen and (max-width: 580px) {
    padding: 85px 0 100px;
    margin-bottom: 0;
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  .tit {
    display: flex;
    align-items: end;
    justify-content: space-between;
    & > div {
      &:first-of-type {
        width: calc(100% - 360px);
        @media screen and (max-width: 990px) {
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 990px) {
      display: block;
    }
    h2 {
      font-size: 54px;
      color: #fff;
      @media screen and (max-width: 1050px) {
        font-size: 44px;
      }
      @media screen and (max-width: 580px) {
        font-size: 34px;
      }
      &:before {
        top: 15px;
      }
    }
    p {
      @media screen and (max-width: 1480px) {
        margin-left: 20px;
      }
      @media screen and (max-width: 580px) {
        margin-left: 0px;
      }
      span {
        color: #d0d8e7;
      }
    }
  }
  .btnGroup {
    @media screen and (max-width: 990px) {
      margin-top: 40px;
      margin-left: 20px;
    }
    @media screen and (max-width: 580px) {
      margin-left: 0px;
    }
    button {
      width: 170px;
      height: 50px;
      background-color: rgba(161, 194, 244, 0.6);
      border-radius: 25px;
      color: #000b1e;
      font-size: 22px;
      font-weight: 600;
      box-shadow: 0 0 15px #131d2e;
      @media screen and (max-width: 1050px) {
        width: 150px;
        height: 45px;
        font-size: 18px;
      }
      @media screen and (max-width: 580px) {
        width: 120px;
        height: 40px;
        font-size: 16px;
      }
      &:first-of-type {
        margin-right: 10px;
      }
      &.active {
        background-color: #bcd5fa;
        color: #26334b;
      }
    }
  }
  .map {
    max-width: 1600px;
    width: 100%;
    margin: 70px auto 0;
    /*max-height: 770px;*/
    padding: 0 40px;
    box-sizing: border-box;
    /*height: 100%;*/
    height: 770px;
    @media screen and (max-width: 1050px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      margin: 50px auto;
    }
    @media screen and (max-width: 550px) {
      height: auto;
      margin: 30px auto;
    }
    img {
      width: 100%;
      @media screen and (max-width: 980px) {
        width: 130%;
      }
    }
  }
  .mapInfo {
    h3 {
      color: #fff;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 40px;
      letter-spacing: -1.5px;
      @media screen and (max-width: 900px) {
        font-size: 26px;
        margin-bottom: 30px;
      }
      @media screen and (max-width: 580px) {
        font-size: 21px;
        margin-bottom: 20px;
      }
    }
    .mapInfoList {
      display: flex;
      gap: 20px;
      @media screen and (max-width: 1450px) {
        flex-direction: column;
      }
      .mapInfoItem {
        width: calc((100% - 40px) / 3);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 10px;
        padding: 30px;
        @media screen and (max-width: 1450px) {
          width: 100%;
        }
        @media screen and (max-width: 560px) {
          padding: 20px;
        }
        h4 {
          color: #fff;
          font-weight: 500;
          font-size: 21px;
          box-sizing: border-box;
          line-height: 1.2;
          @media screen and (max-width: 1450px) {
            font-size: 18px;
          }
          @media screen and (max-width: 560px) {
            font-size: 17px;
          }
          b {
            font-weight: 700;
            display: block;
          }
          small {
            font-size: 16px;
            @media screen and (max-width: 1450px) {
              font-size: 15px;
            }
            @media screen and (max-width: 560px) {
              font-size: 14px;
            }
          }
        }
        p {
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          opacity: 0.8;
          @media screen and (max-width: 1450px) {
            font-size: 15px;
          }
          @media screen and (max-width: 560px) {
            font-size: 14px;
          }
          &.address {
            margin: 20px 0 25px;
            min-height: 52px;
            @media screen and (max-width: 1450px) {
              margin: 20px 0 22px;
              min-height: auto;
            }
          }
          &.tel {
            border-left: 2px solid #fff;
            padding-left: 20px;
          }
          span {
            color: #fff;
            line-height: 1;
            display: block;
            &.mb10 {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
`;
export const Main06 = styled.article`
  padding-top: 70px;
  padding-bottom: 165px;
  @media screen and (max-width: 580px) {
    padding-top: 80px;
    padding-bottom: 120px;
  }
  .form {
    border-top: 2px solid #272727;
    padding-top: 45px;
    @media screen and (max-width: 580px) {
      padding-top: 40px;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      margin-right: 70px;
      @media screen and (max-width: 910px) {
        display: block;
        margin-right: 0;
        margin-bottom: 0;
      }
      textarea {
        resize: none;
        height: 355px;
        width: 100%;
        border: 1px solid #d8d8d8;
        padding: 30px 35px;
        font-size: 16px;
        @media screen and (max-width: 910px) {
          margin-top: 20px;
        }
        @media screen and (max-width: 580px) {
          padding: 20px;
        }
        &::placeholder {
          color: #b0b0b0;
          font-weight: 400;
          font-size: 16px;
        }
      }
      label {
        display: flex;
        align-items: center;
        justify-content: start;
        @media screen and (max-width: 910px) {
          margin-bottom: 40px;
          display: block;
        }
      }
      div {
        display: inline-block;
        width: 125px;
        @media screen and (max-width: 910px) {
          width: 100%;
        }
        &.company {
          width: 185px;
          @media screen and (max-width: 910px) {
            width: 100%;
          }
        }
      }
      span {
        display: inline-block;
        color: #272727;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        &:after {
          content: '*';
          display: inline-block;
          color: #26334b;
          font-size: 20px;
          font-weight: 600;
          position: absolute;
          right: -13px;
          top: 0;
        }
      }
    }
    input {
      width: 350px;
      @media screen and (max-width: 1150px) {
        width: 250px;
      }
      @media screen and (max-width: 910px) {
        width: 100%;
        margin-top: 10px;
      }
      @media screen and (max-width: 580px) {
        font-size: 15px;
      }
      &::placeholder {
        color: #b0b0b0;
        font-weight: 400;
        font-size: 16px;
        @media screen and (max-width: 1150px) {
          font-size: 14px;
        }
        @media screen and (max-width: 910px) {
          font-size: 16px;
        }
        @media screen and (max-width: 580px) {
          font-size: 15px;
        }
      }
    }
    select {
      width: 160px;
      color: #b0b0b0;
      font-weight: 500;
      font-size: 16px;
      appearance: none;
      padding-right: 20px;
      background: #fff url(/images/icon/ico-select-arrow-g.png) no-repeat 94% 50%;
    }
    .email {
      width: 250px;
      margin-right: 25px;
    }
    input,
    select {
      padding: 0 10px;
      border: 0;
      height: 40px;
      border-bottom: 1px solid #c5c5c5;
      font-size: 16px;
    }
  }
  .checkBox {
    h3 {
      color: #222222;
      font-size: 22px;
      font-weight: 600;
      padding-bottom: 25px;
      line-height: 1;
      border-bottom: 2px solid #272727;
      margin-bottom: 40px;
      margin-top: 100px;
      @media screen and (max-width: 580px) {
        margin-top: 50px;
        margin-bottom: 30px;
      }
    }
  }
  .textBox {
    padding: 15px;
    border: 1px solid #dbdbdb;
    overflow-y: auto;
    height: 400px;
    box-sizing: border-box;
    p,
    span {
      color: #626262;
      font-weight: 400;
      font-size: 17px;
      @media screen and (max-width: 910px) {
        font-size: 16px;
      }
      @media screen and (max-width: 580px) {
        font-size: 15px;
      }
    }
    &::-webkit-scrollbar {
      /* 스크롤바 막대 너비 설정 */
      width: 6px;
    }
    /* 스크롤바 막대 설정*/
    &::-webkit-scrollbar-thumb {
      /* 스크롤바 막대 높이 설정 */
      height: 17%;
      background-color: #c3c3c3;
      /* 스크롤바 둥글게 설정 */
      border-radius: 10px;
    } /* 스크롤바 뒷 배경 설정*/
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
    }
    overflow-x: hidden;
  }
  .checkLabel {
    display: flex;
    align-items: center;
    margin: 40px 0 90px;
    @media screen and (max-width: 910px) {
      margin: 20px 0 60px;
    }
    span {
      color: #3b3b3b;
      font-size: 18px;
      font-weight: 500;
      @media screen and (max-width: 910px) {
        font-size: 16px;
      }
      @media screen and (max-width: 400px) {
        font-size: 14px;
      }
      @media screen and (max-width: 340px) {
        font-size: 13px;
      }
    }
  }
  .checkLabel input[type='checkbox']::before {
    content: '✓';
    font-size: 15px;
    position: absolute;
    top: 10px;
    left: 50%;
    overflow: hidden;
    transform: scale(0) translateX(-50%);
    line-height: 1;
    @media screen and (max-width: 580px) {
      top: 8px;
      font-size: 13px;
    }
  }
  .checkLabel input[type='checkbox'] {
    appearance: none;
    position: relative;
    width: 23px;
    height: 23px;
    cursor: pointer;
    border: 0px;
    background: #fff;
    outline: none !important;
    margin: 0px 15px 0px 0px;
    border: 1px solid #b7b7b7;
    border-radius: 3px;
    @media screen and (max-width: 910px) {
      margin: 0px 10px 0px 0px;
    }
    @media screen and (max-width: 580px) {
      width: 20px;
      height: 20px;
    }
    @media screen and (max-width: 340px) {
      margin: 0px 5px 0px 0px;
    }
  }

  .checkLabel input[type='checkbox']:checked {
    background-color: #26334b;
    color: #fff;
  }
  .checkLabel input[type='checkbox']:checked::before {
    transform: scale(1) translate(-50%, -50%);
  }
  .btnGroup {
    text-align: center;
    button {
      width: 230px;
      height: 60px;
      background-color: #26334b;
      background-image: url('/images/icon/ico-btn-arrow-w.png');
      background-repeat: no-repeat;
      background-position: 180px center;
      border-radius: 30px;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      padding: 0 40px;
      box-sizing: border-box;
      @media screen and (max-width: 910px) {
        margin-top: 50px;
      }
      @media screen and (max-width: 580px) {
        width: 100%;
        text-align: center;
        background-position: 70% center;
      }
      @media screen and (max-width: 400px) {
        background-position: 80% center;
      }
    }
  }
`;
export const FormComplete = styled.div`
  width: 650px;
  height: 420px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(25, 25, 25, 0.18);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  @media screen and (max-width: 770px) {
    width: 540px;
  }
  @media screen and (max-width: 580px) {
    width: 450px;
    padding: 0 20px;
  }
  @media screen and (max-width: 470px) {
    width: 400px;
    padding: 0 20px;
  }
  @media screen and (max-width: 410px) {
    width: 350px;
  }
  @media screen and (max-width: 360px) {
    width: 300px;
  }
  h3 {
    color: #282828;
    font-size: 32px;
    font-weight: 600;
    font-family: Pretendard !important;
    line-height: 1;
    padding-top: 100px;
    background-image: url('/images/icon/ico-form.png');
    background-repeat: no-repeat;
    background-position: top center;
    margin-bottom: 15px;
    @media screen and (max-width: 470px) {
      padding-top: 80px;
      padding-bottom: 20px;
    }
    @media screen and (max-width: 360px) {
      font-size: 30px;
    }
  }
  p {
    span {
      color: #515151;
      font-size: 16px;
      font-weight: 400;
      display: block;
      font-family: Pretendard !important;
      font-weight: 400;
      @media screen and (max-width: 470px) {
        display: inline;
      }
      @media screen and (max-width: 360px) {
        font-size: 15px;
      }
    }
  }
  button {
    width: 145px;
    height: 40px;
    background-color: #26334b;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
    margin-top: 50px;
    @media screen and (max-width: 360px) {
      margin-top: 30px;
    }
  }
`;
